import React from "react";
import {Container, Row, Col, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import DocumentMeta from "react-document-meta";

function RussiaPermStateMedicalUniversity() {
  const meta = {
    title: "MBBS Admission in Russia at Perm State Medical University",
    description:
      "MBBS Admission in Russia at Perm State Medical University. About University, Admission Procedure for Indian Students, Eligibility Criteria, Fee Structure, Required Documents, Advantages for Indian Students & find the right Authorized partner.",
    canonical:
      "https://www.mbbsadmissionsinabroad.com/mbbs-admission-russia-perm-state-medical-university",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "MBBS Admission in Russia at Perm State Medical University"
      }
    }
  };
  return (
    <DocumentMeta {...meta}>
      <div>
        <div className="service__bg">
          <h1 className="service__title">
            MBBS Admission in Russia at Perm State Medical University
          </h1>
        </div>
        <Container>
          <div className="blog__post__img">
            <img
              src="assets/images/blog-banners/perm-state-medical-university.png"
              alt="MBBS Admission in Russia at Perm State Medical University"
            />
          </div>
          <ul className="hyperlink">
            <li>
              <a href="#1">
                <img src="/assets/left-arrow.png" alt="Left Arrow" /> About Perm
                State Medical University
              </a>
            </li>
            <li>
              <a href="#2">
                <img src="/assets/left-arrow.png" alt="Left Arrow" /> Indian
                Student Admission Procedure
              </a>
            </li>
            <li>
              <a href="#3">
                <img src="/assets/left-arrow.png" alt="Left Arrow" />{" "}
                Eligibility Criteria of Perm State Medical University
              </a>
            </li>
            <li>
              <a href="#4">
                <img src="/assets/left-arrow.png" alt="Left Arrow" />{" "}
                Requirements for MBBS Admission
              </a>
            </li>
            <li>
              <a href="#5">
                <img src="/assets/left-arrow.png" alt="Left Arrow" /> Perm State
                Medical University Fee Structure
              </a>
            </li>
            <li>
              <a href="#6">
                <img src="/assets/left-arrow.png" alt="Left Arrow" /> Perm State
                Medical University Facility
              </a>
            </li>
            <li>
              <a href="#7">
                <img src="/assets/left-arrow.png" alt="Left Arrow" /> Perm State
                Medical University Advantages for Indian Student
              </a>
            </li>
            <li>
              <a href="#8">
                <img src="/assets/left-arrow.png" alt="Left Arrow" /> New-Lyf
                Education Authorized Indian Partner
              </a>
            </li>
          </ul>
        </Container>
        <section className="blog__post">
          <Container>
            {/*<img src="assets/images/banner-advertisement.png" alt="" /> */}
            <div id="1">
              <h2 className="blog__post__title">
                About Perm State Medical University
              </h2>
              <p className="blog__post__desc">
                Perm State Medical University is the finest option to take
                admission to{" "}
                <Link to="/mbbs-admission-russia-perm-state-medical-university">
                  MBBS University in Russia
                </Link>{" "}
                which was established in 1916. This university is recognized by
                a significant scientific center that is well-known for higher
                medical education and scientific research. The medium of MBBS
                coaching in this university is English. It is approved by the
                MCI & WHO. It comprises of electronic reading room and a center
                of practical skills, and modern computer halls. The MBBS fee of
                Perm State Medical University is very reasonable. The duration
                of MBBS in this university is 6 years that includes 5 years of
                MBBS course and 1 year of internship.
              </p>
            </div>

            <div id="2">
              <h3 className="blog__post__title">
                Indian Student Admission Procedure
              </h3>
              <p className="blog__post__desc">
                Indian students need to follow the below steps to take{" "}
                <b>MBBS Admission in Russia:</b>
              </p>
              <ul className="blog__post__list">
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  The students need to fill the admission form from the online
                  portal of Perm State Medical University
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  They need to submit all the required documents online.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  If the students are selected, they will get an invitation
                  letter within two weeks.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  Once the students receive an invitation letter, they need to
                  pay the tuition fee of the university.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  The students can apply for the student visa process with the
                  original receipt of fee payment and invitation letter of the
                  university
                </li>
              </ul>
            </div>
            <div id="3">
              <h4 className="blog__post__title">
                Eligibility Criteria of Perm State Medical University
              </h4>

              <p className="blog__post__desc">
                The eligibility criteria of the students who want to study
                medical course in <b>top MBBS University in Russia</b> are as
                follows:
              </p>

              <ul className="blog__post__list">
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  The students should have science in their 12th standard and
                  should have an aggregate of 50% marks in physics, chemistry,
                  and Biology.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  The students should be at least 17 years of age to take
                  <b>MBBS admission in Russia.</b>
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  The students should also qualify for the NEET exam with
                  passing marks.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  The students should have English subject in their higher
                  secondary.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  The students must not be more than 25 years of age.
                </li>
              </ul>
            </div>

            <div id="4">
              <h5 className="blog__post__title">
                Requirements for MBBS Admission
              </h5>

              <p className="blog__post__desc">
                The necessary documents for taking{" "}
                <Link to="/mbbs-in-russia">MBBS admission in Russia</Link> to
                study in Perm State Medical University are the following:
              </p>

              <ul className="blog__post__list">
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  A complete filled application form with all accurate details.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  Original and photocopies of Passport.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  Birth certificates and other mandatory certificates must be
                  submitted to the concerned authority.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  Passport size (3cmX4cm) photographs.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  Original and a photocopy of mark sheets of both 10th & 12th
                  standard
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  A scorecard of NEFT (if required)
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  Transfer certificate from the previous school
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  Leaving certificate from the concerned school
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  A certificate of code of conduct must be submitted
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  The bank copy of guardians must be submitted which indicates
                  that they have sufficient funds to pay the fees to Perm State
                  Medical University.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  A medical checkup certificate is also essential
                </li>
              </ul>
            </div>

            <div id="5">
              <h6 className="blog__post__title">
                Perm State Medical University Fee Structure
              </h6>

              <p className="blog__post__desc">
                Perm State Medical University has reasonable fee structure:
              </p>

              <div className="blog__post__table">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Particulars</th>
                      <th>Per annum Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Fees in USD (approx)</td>
                      <td>4,500</td>
                    </tr>
                    <tr>
                      <td>Fee in Indian Rupees (approx)</td>
                      <td>3,15,000</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>

            <div id="6">
              <h6 className="blog__post__title">
                Perm State Medical University Facility
              </h6>

              <p className="blog__post__desc">
                Perm State Medical University provides the following facilities:
              </p>

              <ul className="blog__post__list">
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  Perm State Medical University has many lecture halls,
                  laboratories, library, hostel, canteens, playground, and
                  hospitals to provide under the roof all the amenities for the
                  medical students.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  The environment of the Perm State Medical University campus is
                  safe and calm.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  All essential facilities are provided to the students such as
                  Indian food, accommodation, and wifi, & more.
                </li>
              </ul>
            </div>

            <div id="7">
              <h6 className="blog__post__title">
                Perm State Medical University Advantages for Indian Student
              </h6>

              <ul className="blog__post__list">
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  Perm State Medical University has a low-cost fee structure of
                  MBBS course.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  The medium of coaching the MBBS course in this university is
                  English language.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  This university is a member of The Association of Medical
                  Schools in Europe.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  It consists of 9 comfortable and well-furnished hospitals.
                </li>
                <li>
                  <img src="assets/right-direction.png" alt="Right Direction" />{" "}
                  It provides fundamental theoretical knowledge, practical
                  skills in their specialties to the MBBS students.
                </li>
              </ul>
            </div>

            <div id="9">
              <h6 className="blog__post__title">
                New-Lyf Authorized Indian Partner
              </h6>
              <p className="blog__post__desc">
                New-Lyf education is the authorized Indian partner with{" "}
                <Link to="/top-russian-medical-universities-for-indian-students">
                  MBBS University in Russia
                </Link>
                . We have already placed hundreds of Indian students in Russia
                for pursuing MBBS course in Perm State Medical University that
                taught MBBS course in English language. We have an expert team
                of consultants that provide well-researched assistance &
                comprehensive counseling to the students. We provide complete
                information about Perm State Medical University and clear each
                doubt of the students so that they can study MBBS in Russia’ top
                Perm State Medical University and fulfill their wish.
              </p>
            </div>

            <div className="blog__post__aut_box">
              <Row className="no-gutters">
                <Col md={12} lg={2}>
                  <img
                    className="blog__post__aut_img"
                    src="assets/DL.png"
                    alt="New-Lyf Logo"
                  />
                </Col>
                <Col md={12} lg={10}>
                  <p className="blog__post__aut_info">
                    Dr Vinit Kumar is a Educational Consultant for India &
                    Abroad with more than 18 Years of Experience.He has an
                    extensive background guiding & assisting medical students to
                    find the best university as per their budget.✔3500 +
                    Students✔ 15 Countries✔ MBBS, MBA, PG, UG✔ 100 % Commitment✔
                    Education Loan✔ Job Placements
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </div>
    </DocumentMeta>
  );
}

export default RussiaPermStateMedicalUniversity
