import styled from "styled-components";

export default styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem;
  width: 100%;
  /* background-color: #cccccc; */
  color: #fff;
  margin: 15px;
  /* font-size: 4em; */
`;
